import React from "react"
import Grid from "@material-ui/core/Grid"
import Typography from "@material-ui/core/Typography"
import Button from "@material-ui/core/Button"
import ServiceCard from "./service-card"
import Bar from "./bar"

const Services = () => {
  let services = [
    {
      id: 1,
      image: "calendar-alt-solid.png",
      title: "Full calendar",
      description:
        "This is a fully functioning calendar.  You can expect every thing you can find from other calendars websites.",
    },
    {
      id: 2,
      image: "running-solid.png",
      title: "Built-in activites",
      description:
        "A list of activities are built in and ready for you to drag and drop. They are also fully customisable.",
    },
    {
      id: 3,
      image: "share-solid.png",
      title: "Sharing",
      description:
        "Calendars can be shared to any one any where with a few clicks.  Password can be used to secure your sharings.",
    },
    {
      id: 4,
      image: "google-brands.png",
      title: "Integrations",
      description:
        "Export event to your google calendar is easy.",
    },
    {
      id: 5,
      image: "store-solid.png",
      title: "Market place (Coming)",
      description:
        "Where you can show off your calendars and help others.",
    },
    {
      id: 6,
      image: "hand-holding-no-usd-solid.png",
      title: "Free to use",
      description: "Free to use forever. No signup is required.",
    },
  ]

  return (
    <Grid
      id="features"
      container
      spacing={3}
      style={{
        textAlign: "center",
        minHeight: 200,
        padding: 10,
      }}
    >
      <Grid
        item
        xs={12}
        container
        direction="column"
        justify="center"
        alignItems="center"
      >
        <Typography variant="h5">Our Features</Typography>
        <Bar />
        <Typography variant="subtitle1" style={{ margin: 30 }}>
          School calendar is built with the features below
        </Typography>
      </Grid>

      {services.map(service => (
        <Grid item xs={12} sm={4} key={service.id}>
          <ServiceCard service={service} />
        </Grid>
      ))}

      {/* <Grid item xs={12}>
        <Button variant="outlined" color="primary" size="large">
          Learn More
        </Button>
      </Grid> */}
    </Grid>
  )
}
export default Services
